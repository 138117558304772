.conference-hero {
  margin-top: 150px;
  margin-bottom: 100px;
}

@include respond("tab-land") {
  .conference-hero {
    margin-top: 120px;
    margin-bottom: 80px;
  }
}