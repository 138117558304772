/* ==================
	8.22 Testimonial
=================== */
.thumb-wraper {
  display: inline-block;
  position: relative;

  @include respond("phone") {
    padding: 15px;
  }
  .bg-img {
    position: relative;
    z-index: -2;
  }
  ul {
    li {
      position: absolute;
      a {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        overflow: hidden;
        display: block;
        box-shadow: 0px 10px 60px 0px rgba(31, 34, 120, 0.1);
        @include transitionMedium;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &:after {
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background-color: $primary;
        @include transitionMedium;
        z-index: -1;
        transform: scale(0);
        border-radius: 50%;
      }
      &.active {
        &:after {
          transform: scale(1.14);
        }
      }
      &:nth-child(1) {
        top: 100px;
        left: 20px;
      }
      &:nth-child(2) {
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:nth-child(3) {
        top: 100px;
        right: 20px;
      }
      &:nth-child(4) {
        right: 30px;
        bottom: 110px;
      }
      &:nth-child(5) {
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:nth-child(6) {
        left: 30px;
        bottom: 110px;
      }
      @include respond("phone") {
        &:nth-child(2) {
          top: 10px;
        }
        &:nth-child(5) {
          bottom: 32px;
        }
        a {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
.testimonial-bx {
  position: relative;
  border-radius: 40px 0 40px 40px;
  box-shadow: 0px 0px 30px rgba(185, 67, 0, 0.1);
  background-color: $primary;
  padding: 50px;
  margin: 50px 20px 20px;

  &:after {
    content: "\f10e";
    position: absolute;
    right: 40px;
    bottom: 20px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 92px;
    color: #fff;
    opacity: 0.3;
    line-height: 92px;
  }
  .testimonial-content {
    p {
      line-height: 28px;
      font-size: 18px;
      color: $white;
      font-weight: 400;
      font-style: italic;
      margin-bottom: 30px;
    }
  }
  .client-info {
    .name {
      font-weight: 600;
      color: #fff;
      margin-bottom: 8px;
      font-size: 22px;
    }
    p {
      font-weight: 600;
      color: $white;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  .quote-icon {
    position: absolute;
    top: -50px;
    left: 50px;
    i {
      font-size: 36px;
      color: $primary;
      width: 75px;
      height: 75px;
      text-align: center;
      background-color: #fff;
      line-height: 75px;
      border-radius: 20px;
      box-shadow: 0px 10px 60px 0px rgba(31, 34, 120, 0.1);
    }
  }
  @include respond("tab-land") {
    border-radius: 30px 0 30px 30px;
    padding: 50px 30px 30px;

    .testimonial-content p {
      line-height: 26px;
      font-size: 16px;
    }
    .quote-icon {
      top: -45px;
      left: 30px;
    }
  }
  @include respond("phone") {
    margin: 50px 0px 0px;
    &:after {
      font-size: 50px;
      line-height: 72px;
    }
    .quote-icon {
      top: -35px;
      i {
        font-size: 28px;
        width: 65px;
        height: 65px;
        line-height: 65px;
      }
    }
  }
}

.testimonial-slide {
  padding-bottom: 75px;
  .slick-arrow {
    height: 50px;
    width: 50px;
    border-radius: 12px;
    background-color: #ffe4d5;
    color: $secondary;
    font-size: 24px;
    bottom: 0;
    top: auto;
    @include transitionMedium;
    font-size: 0;

    &:before {
      content: "";
      font-family: "Line Awesome Free";
      font-weight: 900;
      color: var(--primary);
      font-size: 24px;
    }
    &:hover {
      background-color: $secondary;
      color: #fff;
    }
  }
  .slick-prev {
    left: 20px;
    &:before {
      content: "\f060";
    }
  }
  .slick-next {
    right: auto;
    left: 85px;
    &:before {
      content: "\f061";
    }
  }
  .swiper-pagination {
    right: 30px;
    display: inline-block;
    text-align: right;
    left: auto;
    width: auto;
    bottom: 0;
    color: $secondary;
    font-weight: 700;

    .swiper-pagination-current {
      font-size: 25px;
    }
    .swiper-pagination-total {
      font-size: 15px;
      opacity: 0.5;
    }
  }
  @include respond("phone") {
    padding-bottom: 100px;
    .slick-prev {
      left: 0;
    }
    .slick-next {
      left: 60px;
    }
    .swiper-pagination {
      right: 10px;
    }
  }
}
.testimonial-wraper {
  .pt-img1 {
    top: 165px;
    left: 180px;
  }
  .pt-img2 {
    bottom: 110px;
    left: 150px;
    @include respond("laptop-small") {
      bottom: 30px;
      left: 30px;
    }
  }
  .pt-img3 {
    top: 140px;
    right: 90px;
  }
  .pt-img4 {
    bottom: 160px;
    right: 160px;
    @include respond("laptop-small") {
      bottom: 30px;
      right: 30px;
    }
  }
}
.conference-marketing-bx {
  position: relative;
  border-radius: 40px 40px 40px 40px;
  box-shadow: 0px 0px 30px rgba(185, 67, 0, 0.1);
  background-color: $primary;
  padding: 50px;
  margin: 50px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  .conference-marketing-content {
    ul {
      margin-top: 32px;
      li {
        font-size: larger;
        color: $white;
      }
    }
  }
  .conference-marketing-bx-image {
    width: 80%;
    img {
      width: 100%;
      border-radius: 16px;
    }
  }
  h2 {
    color: $white;
  }

  &:after {
    position: absolute;
    right: 40px;
    bottom: 20px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 92px;
    color: #fff;
    opacity: 0.3;
    line-height: 92px;
  }

  .client-info {
    .name {
      font-weight: 600;
      color: #fff;
      margin-bottom: 8px;
      font-size: 22px;
    }
    p {
      font-weight: 600;
      color: $white;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  @include respond("tab-land") {
    padding: 50px 30px 30px;

    .testimonial-content p {
      line-height: 26px;
      font-size: 16px;
    }
  }
  @include respond("phone") {
    flex-direction: column;
    margin: 50px 0px 0px;
    &:after {
      font-size: 50px;
      line-height: 72px;
    }
  }
}
