.fullscreenIframe {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%; /* Assuming a 16:9 aspect ratio for the iframe */
  width: 100%;
}

.responsiveIframe {
  position: absolute;
  overflow: hidden;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
